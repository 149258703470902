.grecaptcha-badge {
    display: none;
}
.google-captcha {
    font-size: 10px;
    margin-bottom: 5px;
}
.v-center {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 30px;
	justify-content: space-between;
	a {
		color: #003845;
	}
	@media screen and (max-width: 675px) {
		display: block;
		text-align: center;
	}
}
.new-homepage-hero-heading {
    position: absolute;
    padding: 3%;
    top: 25%;
    left: 4%;
    display: block;
    width: 45%;
    border: 5px solid #eee;
    background-color: rgba(0, 0, 0, 0.5);
}
.new-homepage-hero-heading h1 {
    color: #fff;
    font-size: 36px;
    font-weight: 600;
    margin-top: 0;
}
.new-homepage-hero-heading h3 {
    color: #fff;
    margin-top: 6px;
    margin-bottom: 20px;
    font-size: 26px;
    font-weight: normal;
}
.new-homepage {
    position: relative;
}
.new-homepage-video-container video {
       /* object-fit: contain; */
    /* object-fit: fill; */
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
}
audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline;
}
.new-homepage-video-container {
    width: 100%;
    margin-top: 90px;
    height: calc(100vh - 90px);
    overflow: hidden;
    background-color: white;
}
@media screen and (max-width: 767px) {
	.visible-xs .item {
	    display: block;
	    width: 100%;
	    height: 436px;
	    background: url(../images/livein_banner_small.jpg) no-repeat;
	    background-position: center;
	    background-size: cover;
	}
}